@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 24px;

  @media (--viewportMedium) {
    padding: 72px 100px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  margin: 24px 0;
  padding: 40px;
  background: var(--matterColorBright);
  border-radius: 16px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);

    margin: 0 auto 0 auto;
    width: 75%;
  }
}

.title {
  text-align: center!important;
  color: var(--matterColorBright);

  @media (--viewportMedium) {
    text-align: left!important;
  }
}

.featuresMain {
  composes: baseColumn;
}

.blockContainer {
  padding: 64px 32px 64px 32px;

  &:first-child {
    padding: 0 32px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  background-color: var(--matterColorBright);
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
