@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 24px;

  @media (--viewportMedium) {
    padding: 64px 100px;
  }
}

.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  margin: 0 auto;
  padding: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
    padding: 24px 100px;
  }
}

.title {
  composes: align h2 from global;
  text-align: center;
  margin: auto;
  width: 100%;

  @media (--viewportMedium) {
    text-align: center!important;
    width: 100%;
  }
}

.featuresMain {
  composes: baseColumn;
}

.blockContainer {
  padding: 64px 32px 0 32px;

  &:first-child {
    padding: 0 32px;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 40px;
  }
}

.blockHomebase {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--matterColorBright);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  padding: 0 16px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #87AFE6;

  padding: 0 16px;
  width: calc(100% );

  @media (--viewportMedium) {
    padding: 0 32px;
  }
}

.blockTitleHeader {
  flex-direction: column;
  color: #87AFE6;
  text-align: center;
  height: auto;
  width: 100%;

  & > span,
  & > img,
  & > div,
  & > a {
    margin: auto;
  }

  @media (--viewportMedium) {
    display: none;
    height: 120px;
  }
}

.blockTitle {
  display: flex;
  flex-direction: column;
  color: #87AFE6;
  text-align: center;
  height: 120px;
  width: 100%;

  & > span,
  & > img,
  & > div,
  & > a {
    margin: auto;
  }
}

.blockTitleFiller {
  display: none;
  flex-direction: column;
  color: #87AFE6;
  text-align: center;
  height: 120px;
  width: 100%;

  & > span,
  & > img,
  & > div,
  & > a {
    margin: auto;
  }

  @media (--viewportMedium) {
    display: flex;
  }
}

.subtitle {
  font-size: 14px;
  line-height: 18px;
}

.logo {
  width: 200px;
  height: 30px;
  object-fit: contain;
}

.blockTextHeader {
  display: none;
  color: #87AFE6;

  text-align: left;
  height: 80px;
  width: 100%;

  & > span {
    margin: auto 0;
  }

  @media (--viewportMedium) {
    display: flex;
    gap: 8px;
  }
}

.blockMobileText {
  display: flex;
  color: var(--matterColorBright);
  font-weight: bold;
  @media (--viewportMedium) {
    display: none;
  }
}

.blockText {
  display: flex;
  justify-content: space-between;
  color: #87AFE6;

  text-align: center;
  height: 80px;
  width: 100%;

  & > span,
  & > img {
    margin: auto 0;
  }

  & > .blockMobileText,
   > .blockMobileTextH {
    margin: auto 0;
  }

  @media (--viewportMedium) {
    & > span,
    & > img {
      margin: auto;
    }

    & > .blockMobileText,
    > .blockMobileTextH {
      display: none;
    }
  }
}

.blockMobileTextH {
  color: var(--marketplaceColor);
  font-weight: bold;
}

.blockHomebase .blockText,
.blockHomebase .blockTitle {
  border-bottom: 1px solid #0A195A0F;
  
  &:last-child {
    border-bottom: none;
  }
}

.block .blockText,
.block .blockTitle,
.block .blockTextHeader {
  border-bottom: 1px solid #E6FFFF40;

  &:last-child {
    border-bottom: none;
  }
}

.button {
  background-color: var(--attentionColor);
  border-radius: 10px;

  padding: 12px 24px;
  height: fit-content;
  min-height: none;
  width: 100%;
}