@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.hasShortCenter {
  margin: 0 auto 32px;
  width: 100%;

  @media (--viewportMedium) {
    width: 70%;
    margin: 0 auto 64px;
  }
}

.hasShort {
  width: 100%;

  @media (--viewportMedium) {
    width: 70%;
  }
}