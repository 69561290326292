@import '../../../styles/customMediaQueries.css';

/* Styles for custom sections: classes shared by <SectionColumns>, <SectionArticle>, <SectionFeatures>, <SectionCarousel> etc. */
.sectionDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 0 24px;
  position: relative;

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: flex-start;
    padding: 0 100px;
  }
}

.blockContainer {
  padding: 64px 24px 0 24px;

  &:first-child {
    padding: 0 24px;
  }

  @media (--viewportMedium) {
    padding: 48px 100px 0 100px;

    &:first-child {
      padding: 0 100px;
    }
  }
}

.align {
  text-align: center;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align h2 from global;
  color: var(--marketplaceColor);
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin: auto;
  width: 100%;

  @media (--viewportMedium) {
    font-size: 45px;
    line-height: 54px;
    text-align: left;
    margin: 32px 0 0;
    width: 100%;
  }
}

.description {
  composes: align;
  max-width: 65ch;
  margin: auto;
}

.ctaButton {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  background-color: var(--successColor);
  border: none;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin: 0 auto;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: var(--successColor);
  }

  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: 32px;
  }
}

/**
 * Theme: dark
 * These styles are at the bottom of the file so that they overwrite rules for default "light" theme.
 */
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme h4,
.darkTheme h5,
.darkTheme h6 {
  color: var(--matterColorLight);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

.darkTheme p,
.darkTheme li,
.darkTheme blockquote {
  color: rgba(255, 255, 255, 0.85);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

/* link on dark theme */
.darkTheme a {
  color: white;
  /* text-decoration: underline; */

  &:hover {
    text-decoration: none;
    /* color: var(--marketplaceColorLight); */
  }
}

/* button on dark theme */
.darkTheme .ctaButton {
  border: 1px solid var(--marketplaceColorDark);
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.darkTheme hr {
  border-color: var(--matterColor);
}

/* dark inline code */
.darkTheme code {
  background-color: var(--matterColor);
  color: var(--matterColorNegative);
}

/* dark code block */
.darkTheme pre {
  background-color: var(--matterColor);
}
