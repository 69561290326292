@import '../../../../styles/customMediaQueries.css';

.sectionDetails {
    display: flex;
    height: 30vh;

    @media (--viewportMedium) {
        min-height: 400px;
    }

    @media (--viewportLarge) {
        min-height: 400px;
    }
}

.title {
    text-align: center;
    margin: auto;
    width: 60%;
}