@import '../../../../styles/customMediaQueries.css';
.blockContainer {
  padding: 64px 24px;

  &:first-child {
    padding: 0 24px;
  }

  @media (--viewportMedium) {
    padding: 72px 100px;

    &:first-child {
      padding: 0 100px;
    }
  }
}

.title {
  text-align: center!important;
}
 
.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  margin: 0;

  @media (--viewportMedium) {
    gap: 32px;
  }
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}
.threeColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}
.fourColumns {
  composes: baseColumn;
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
