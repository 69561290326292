@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 24px 0;

  @media (--viewportMedium) {
    padding: 48px 0;
  }
}

.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  margin: 0 auto;
  padding: 24px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
    padding: 24px 100px;
  }
}

.title {
  color: var(--attentionColor);
  text-align: center!important;
}

.featuresMain {
  composes: baseColumn;
}

.blockContainer {
  padding: 64px 24px 0;

  &:first-child {
    padding: 0 24px;
  }

  @media (--viewportMedium) {
    padding: 64px 100px 0;

    &:first-child {
      padding: 0 100px;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
