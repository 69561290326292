@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 24px 0;

  @media (--viewportMedium) {
    padding: 48px 0;
  }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0;

    @media (--viewportMedium) {
        padding: 48px 0;
    }
}

.baseColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  padding: 24px;

  @media (--viewportMedium) {
    flex-direction: row;    
    gap: 48px;
    padding: 24px 100px;
  }
}

.title {
  color: var(--attentionColor);
  text-align: center!important;
}

.featuresMain {
  composes: baseColumn;
}

.blockContainer {
  padding: 64px 24px 0;

  &:first-child {
    padding: 0 24px;
  }

  @media (--viewportMedium) {
    padding: 64px 100px 0;

    &:first-child {
      padding: 0 100px;
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 30%;
  }

}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
