@import '../../../../styles/customMediaQueries.css';

.root {

}

.blockContainer {
    padding: 64px 24px;

    @media (--viewportMedium) {
        padding: 48px 100px 96px;
    }
}

.title {
    text-align: center;
}

.section {
    width: 100%;
}

.baseColumn {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    margin: 0;

    @media (--viewportMedium) {
        gap: 120px;
    }
}

.oneColumn {
    composes: baseColumn;
}

.twoColumns {
    composes: baseColumn;

    @media (--viewportMedium) {
        grid-template-columns: repeat(2, calc((100% - 120px) / 2));
    }
}

.threeColumns {
    composes: baseColumn;

    @media (--viewportMedium) {
        grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
    }
}

.fourColumns {
    composes: baseColumn;

    @media (--viewportMedium) {
        grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
    }
}

.noSidePaddings {
    padding-left: 0;
    padding-right: 0;
}

.ctaButton {
    border: 1px solid var(--matterColorBright);
    border-radius: 10px;
    transition: var(--transitionStyleButton);
    font-family: var(--fontFamilyRegular);

    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;

    color: var(--marketplaceColor);
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 12px 18px;
    text-decoration: none;

    &:hover {
        color: var(--attentionColor);
        border: 1px solid var(--attentionColor);
        background-color: rgba(40, 90, 200, .2);
        text-decoration: none;
    }

    @media (--viewportMedium) {
        font-size: 18px;
        line-height: 24px;
    }
}