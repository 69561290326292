@import '../../../../styles/customMediaQueries.css';

.articleMain {
    /* 720px + (2 * 32px) == 784 */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
    margin: 0 auto;
}

.noSidePaddings {
    padding-left: 0;
    padding-right: 0;
}

.hasShort {
    width: 100%;

    @media (--viewportMedium) {
        width: 80%;
    }
}

.description {
    color: var(--inputColor);
    font-size: 14px;

    @media (--viewportMedium) {
        font-size: 16px;
    }
}