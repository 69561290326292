@import '../../../../styles/customMediaQueries.css';

.root {
  padding: 24px;

  @media (--viewportMedium) {
    padding: 72px 100px;
  }
}

.sectionDetails {
  padding: 24px 0;
}

.content {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.baseColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  margin: 0 auto 0 auto;
  padding: 0 40px;
  background: var(--matterColorBright);
  border-radius: 16px;

  @media (--viewportMedium) {
    margin: 0 0 0 auto;
    width: 75%;
  }
}

.title {
  text-align: center!important;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    text-align: left!important;
  }
}

.featuresMain {
  composes: baseColumn;
}

.blockContainer {
  padding: 0;

  &:first-child {
    padding: 0;
  }
}

.block {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: var(--matterColorBright);
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
